import BasePlugin from '../BasePlugin'

export default class ReturnForRevisionAdjustmentRequest extends BasePlugin {
  async execute () {
    let DMKIEmployee = JSON.parse(this.context.getCard().getRecordData().attr_4890_)
    let DIPIAEmployee = JSON.parse(this.context.getCard().getRecordData().attr_4963_)
    let userId = JSON.parse(atob(localStorage.getItem('user-token').split('.')[1])).user.id

    if (DMKIEmployee[0].id === userId || DIPIAEmployee[0].id === userId) {
      let adjustmentRequest = this.context.getModel()
      let adjustmentRequestData = {
        'adjustmentRequestId': adjustmentRequest.id,
        'actionTypeId': 2
      }
      let message

      if (adjustmentRequest.attr_4664_ || adjustmentRequest.attr_4949_) {
        if (adjustmentRequest.attr_4664_) {
          message = `Необходимо согласовать данное действие с ${DMKIEmployee[0].name} и снять блокировку новых затрат.<br>`
        }

        if (adjustmentRequest.attr_4963_) {
          message += `Необходимо согласовать данное действие с ${DIPIAEmployee[0].name} и снять блокировку переходящих затрат.`
        }

        this.context.$msgbox({
          type: 'info',
          message: message,
          dangerouslyUseHTMLString: true
        })
      } else {
        this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/ReturnForRevisionAdjustmentRequestCommand`,
          { 'adjustmentRequestId': adjustmentRequest.id },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )

        this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SetAdjustmentRequestRuleCommand`,
          adjustmentRequestData,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        this.context.$message({
          type: 'success',
          message: 'Процедура согласования возвращена на доработку.'
        })
        this.context.getCard().$emit('cancelChanges')
      }
    } else {
      this.context.$msgbox({
        type: 'info',
        message: 'Вернуть процедуру на доработку может только ответственный ДИПиА/ДМиКИ'
      })
    }
  }
}
